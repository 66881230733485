<template>
  <div>
    <div>
      <div class="content1">
        <div class="all">
          <div class="SearchBar">
            <!--搜索框-->
            <el-form
                ref="search"
                :inline="true"
                :model="search"
                size="small"
                label-width="80px"
            >
              <el-form-item  label="时间段">
                <el-date-picker
                    v-model="times"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    clearable
                    value-format="yyyy-MM-dd HH:mm:ss"
                    :default-time="['00:00:00', '23:59:59']"
                    @change="getSearchDate"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="巡线员">
                <el-input
                    v-model="search.searchText"
                    placeholder="请输入巡线员姓名"
                    class="SearchInput"
                    clearable
                ></el-input>
              </el-form-item>
              <el-form-item label-width="20px" label=" ">
                <div>
                  <el-button type="primary" size="small" @click="getList(1)"
                  >查询</el-button
                  >
                  <el-button type="warning" size="small" @click="reset"
                  >重置</el-button
                  >
                </div>
              </el-form-item>
            </el-form>
          </div>
          <div class="table">
            <!--数据表格-->
            <el-table
                ref="tableData"
                :data="tableData"
                style="width: 100%;"
                border
                height="calc(100vh - 333px)"
                :stripe="true"
                :header-cell-style="{
                  color: '#333',
                  fontFamily: 'MicrosoftYaHeiUI',
                  fontSize: '14px',
                  fontWeight: 900,
                  textAlign:'center',
                  background: '#f8f8f9',
                }"
            >
              <el-table-column
                  type="index"
                  label="序号"
                  width="70"
                  align="left"
              >
              </el-table-column>
             
              <el-table-column
                  prop="createTime"
                  label="日期"
                  align="left"
                  width="100"
              >
              <template slot-scope="scope">
                 <span v-if="scope.row.createTime">{{scope.row.createTime.substring(0,10)}}</span>
                </template>
              </el-table-column>
              <el-table-column
                  prop="realname"
                  label="巡线员"
                  align="left"
                  show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                  prop="scheduleStartTime"
                  label="上班时间"
                  align="left"
                  show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                  prop="scheduleEndTime"
                  label="下班时间"
                  align="left"
                  show-overflow-tooltip
              >
              <template slot-scope="scope">
                {{!scope.row.scheduleEndTime  ? '--' : scope.row.scheduleEndTime}}
                </template>
              </el-table-column>
              <el-table-column
                  prop="patrolTimes"
                  label="巡检时长"
                  align="left"
                  show-overflow-tooltip
              >
              <template slot-scope="scope">
                {{parseInt(scope.row.patrolTimes/60/60%24)}} <span>小时</span>
                {{parseInt(scope.row.patrolTimes/60%60)}} <span>分钟</span>
                {{parseInt(scope.row.patrolTimes%60)}} <span>秒</span>
                </template>
              </el-table-column>
              <el-table-column
                  prop="suspendTimes"
                  label="暂停时长"
                  align="left"
                  show-overflow-tooltip
              >
              <template slot-scope="scope">
                {{parseInt(scope.row.suspendTimes/60/60%24)}} <span>小时</span>
                {{parseInt(scope.row.suspendTimes/60%60)}} <span>分钟</span>
                {{parseInt(scope.row.suspendTimes%60)}} <span>秒</span>
                </template>
              </el-table-column>
              <el-table-column
                  prop="startElectric1"
                  label="开始电量"
                  align="left"
                  width="85"
                  show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                  prop="surplusElectric1"
                  label="剩余电量"
                  width="85"
                  align="left"
                  show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                  align="left"
                  prop="projectNumber"
                  label="操作"
                  width="150"
                  fixed="right"
              >
                <template slot-scope="{row}">
                  <el-button type="primary" size="mini"
                  v-if="$anthButtons.getButtonAuth('lookTrack')"
                   @click="showDetil(row)">查看轨迹</el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="pageBox">
              <el-pagination
                  :current-page="search.current"
                  :background="true"
                  :page-sizes="[10,30, 50, 100]"
                  :page-size="search.size"
                  layout="total, prev, pager, next, sizes, jumper"
                  :total="total"
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
              >
              </el-pagination>
            </div>
          </div> 
        </div>
      </div>
    </div>
    <map-locus ref="mapLocus"></map-locus>
  </div>
</template>

<script>
import {getTrackWorkPage,getWorkTrackPath} from "@/RequestPort/checkingIn";
import mapLocus from "./mapLocus.vue";
export default {
  components: { mapLocus },
  name: "workLocus",
  data(){
    return{
      search:{current:1,size: 100,startDate:'',endDate:'',searchText:''},
      times:[],
      tableData:[],
      total:0
    }
  },
  mounted() {
    this.getList(1)
  },
  methods:{
    getSearchDate(){
      if(this.times && this.times.length>0){
        this.search.startDate = this.times[0]
        this.search.endDate = this.times[1]
      }else{
        this.search.startDate = '';
        this.search.endDate = '';
      }
      // this.getList(1)
    },
    getList(page){
      if(page){
        this.search.current = 1
      }
      getTrackWorkPage(this.search).then(res=>{
        this.tableData  = res.data.records
        this.total = res.data.total
      })
    },
    reset(){
      this.search = {current:1,size: 100,startDate:'',endDate:'',searchText:''}
      this.times = []
      this.getList(1)
    },
    handleSizeChange(val){
      this.search.size=  val
      this.getList(1)
    },
    handleCurrentChange(val){
      this.search.current=  val
      this.getList()
    },
    showDetil(row){
      // 五公里
      getWorkTrackPath({userId:row.executorPeopleId,id:row.id,precise:5000}).then(res => {
        this.$refs.mapLocus.open(row,res.data)
      })
      // this.$refs.mapLocus.open(row)
    }
  }
}
</script>

<style scoped>
.SearchBar {
  /* border: 1px solid #ecedf1; */
  border-radius: 5px;
  margin: 10px;
  padding-top: 1%;
  background-color: #ffffff;
}
.table {
  padding: 20px 20px 15px 20px !important;
  border-radius: 5px;
  margin: 10px;
  background-color: #ffffff;
  height: auto;
}
.el-pagination{
        margin: 15px 0px 0px;
  }
</style>